import React from 'react';
import { Link } from 'react-router-dom';


const AuthLayout = ({ children, title }) => {
  return (
    <div className="al-container">
      <div className="al-content">
        <img
          className="al-logo"
          src="/logo3.png"
          alt="AgenTime - Votre solution de gestion du temps et d'agenda"
        />
        <h2 className="al-title">
          {title}
        </h2>
      </div>

      <div className="al-form-container">
        <div className="al-form-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
