import React from 'react';
import { Mail, Lock } from 'lucide-react';

const AuthForm = ({ title, children }) => {
  return (
    <div className="auth-container">
      <div className="auth-box">
        <div className="auth-header">
          <img 
            src="/logo3.png" 
            alt="Logo" 
            className="auth-logo"
          />
          <h2 className="auth-title">{title}</h2>
        </div>

        <div className="auth-form">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
